import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element, {size: 'small', zIndex: 3000})
import { message } from '@/utils/resetMessage.js';
Vue.prototype.$message = message;
window.$message = message;
// import htmlToPdf from "@/utils/pdf" //注意，这里的路径是我本地的路径，需要自行应用，请不要随便复制进去导致报错
// Vue.use(htmlToPdf)
import {getUserIndex} from "@/utils/validate.js";
Vue.prototype.getUserIndex = getUserIndex
Vue.config.productionTip = false;

import './assets/icons' // icon
import './assets/css/common.scss';
import './utils/uploadFiles.js'; // 富文本编辑器-图片上传

import './utils/jweixin-1.4.0.js'; 
import './utils/hybrid_html_uni.webview.1.5.5'; 
// 注册富文本
import VueUeditorWrap from 'vue-ueditor-wrap'
Vue.component('VueUeditorWrap',VueUeditorWrap)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
