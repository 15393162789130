<template>
    <div class='myHeader'>
        <div class="left">
            <img :src="require('@/assets/images/logo.png')" alt="">
            <span>炎黄族谱管理平台</span>
        </div>
        <div class="right" v-if='userInfo.userName'>
            <i class="el-icon-user-solid"></i>
            <span> {{userInfo.userName}} </span>
            <ul class="head-ul">
                <li @click="loginOut" class="head-ul-li">退出登录</li>
            </ul>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
export default {
    name:'myHeader',
    data(){
       return{
       }
    },
    props:{
        userInfo:{
            type:Object,
            default:()=>{return {userName:''}}
        }
    },
    methods:{
        loginOut(){
            Cookies && Cookies.remove('zuji-token');
            Cookies && Cookies.remove('zujiUserInfo');
            this.$router.push({path:'/login'})
        }
    }
}
</script>
<style lang='scss' scoped>
.myHeader{
    width: 100%;
    height: 60px;
    background-color: #afc9e6;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px 20px;
    border-bottom: 1px solid #ebebeb99;
    .left{
        float: left;
        height: 100%;
        font-size: 0;
        img{
            width: 40px;
            height: 40px;
            display: inline-block;
            vertical-align: middle;
        }
        span{
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
            color: #333;
            margin-left: 10px;
        }
    }
    .right{
        float: right;
        font-size: 0;
        height: 40px;
        padding: 10px 0;
        cursor: pointer;
        i{
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            color: #1890ff;
            background-color: #ffffff99;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
        }
        span{
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            color: #fff;
            margin-left: 10px;
            height: 20px;
            line-height: 20px;
        }
        &:hover .head-ul{
            display: block;
        }
        .head-ul{
            display: none;
            width: 120px;
            position: absolute;
            right: 0;
            top: 40px;
            padding-top: 20px;
            padding-bottom: 10px;
            background-color: #1f2d3d;
            .head-ul-li{
                width: 100%;
                height: 40px;
                line-height: 40px;
                color: #fff;
                font-size: 14px;
                text-align: center;
                &:hover{
                    background-color: #ddd;
                    color: #1890ff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>