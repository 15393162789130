<template>
  <div id="app">
    <template v-if='showCont'>
      <myHeader :userInfo='userInfo'></myHeader>
      <div class="cont contBox">
        <div class="left">
          <leftMenu></leftMenu>
        </div>
        <div class="left_cont">
          <router-view />
        </div>
      </div>
    </template>
    <template v-else>
          <router-view />
    </template>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import myHeader from "@/views/components/myHeader.vue";
import leftMenu from "@/views/components/leftMenu/leftMenu.vue";
import {mapGetters} from "vuex"
export default {
  data() {
    return {
      showHeader: false,
      // showCont:false,
      token:false,
      userInfo:{}
    };
  },
  computed:{
    ...mapGetters(['showCont'])
  },
  watch:{
    '$route.path': {
        handler(toPath, fromPath){
            // console.log('当前页面路由地址：' + toPath)
            if (Cookies.get("zuji-token")) {
              this.showHeader = true;
              // this.$router.push({ path: "/index" });
              // console.log(this.showHeader);
              this.userInfo = JSON.parse(Cookies.get("zujiUserInfo"))
            }else{
            console.log('路由变化了')
              if(toPath == '/chartPicture'){
                console.log(this.$route)
              }else{
                console.log(toPath)
                // this.$router.push({ path: "/login" });
                this.showHeader = false;
              }
            }
        },
        deep: true, // 深度监听
        immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  created() {
    // console.log(this.$route);
    if (Cookies.get("zuji-token")) {
      this.showHeader = true;
      // this.$router.push({ path: "/index" });
      // console.log(this.showHeader);
    }
  },
  components: { myHeader ,leftMenu},
 
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .cont {
    width: 100%;
    height: 100%;
    .left_cont {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .contBox {
    height: calc(100% - 60px);
    .left {
      float: left;
      width: 200px;
      height: 100%;
    }
    .left_cont {
      float: left;
      width: calc(100% - 200px);
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
